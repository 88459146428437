import React from "react"
import styled from "styled-components"
import "@fontsource/eb-garamond"
import { StaticImage as Image } from "gatsby-plugin-image"

const Careers = () => {
  return (
    <SectionContainer>
      <SectionWrapper>
        <ColumnOne>
          <SectionH1>CAREERS</SectionH1>

          <SectionP>
            Send your curriculum vitae at careers@thecarnival.pk
          </SectionP>
        </ColumnOne>
        <ColumnTwo>
          <Image src="../../images/I12.png" alt="Sec2" />
        </ColumnTwo>
      </SectionWrapper>
    </SectionContainer>
  )
}

export default Careers

const SectionContainer = styled.div`
  width: 100%;
  background: white;
  color: black;
  padding: 6rem 0rem;

  margin: auto;
`
const SectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1 1rem;
  grid-gap: 2.5rem;

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr;
  }
`
const ColumnOne = styled.div`
  padding: 0 1rem;
  @media screen and (max-width: 960px) {
    padding: 0 1rem;
  }
`
const ColumnTwo = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 1rem;

  @media screen and (max-width: 960px) {
    padding: 0 1rem;
  }
`

const SectionH1 = styled.h1`
  font-family: "League Spartan", sans-serif;
  font-size: 3.5rem;
  font-weight: 600;
  color: #545454;
  text-align: center;
  letter-spacing: 1px;

  @media screen and (max-width: 960px) {
    text-align: center;
  }
`

const SectionP = styled.p`
  color: #545454;
  font-size: 1.3rem;
  text-align: center;
  font-family: "EB Garamond";
  line-height: 1.7;
  font-weight: 800;

  @media screen and (max-width: 960px) {
    text-align: center;
  }
`
